.splash-bg{
    min-height:100%;
      background:linear-gradient(rgba(63, 74, 89, 0.90), rgba(63, 74, 89, 0.90)), url("../../../public/Images/splash-bg.svg");
      background-size:cover;
      height:100vh;
}
.splash-bg img{
    text-align: center;
    margin: 0 auto;
    padding-top: 16%;
    display: block;
}
.splash-bg h3{
    text-align: center;
    color: #fff;
}