.garment-create-fit-container {
  background-color: white;
  padding: 20px 30px;
  margin-top: 30px;
  border: 1px solid rgba(214, 214, 214, 1);
}

.create-fit-wrapper {
  background-color: #f3f5f6 !important;
}

.input-create-fit > input {
  width: 100%;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  border-radius: 4px;
  padding: 10px 10px;
  height: 48px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.input-create-fit > .label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label-row > button {
  border-radius: 50px !important;
  width: 20px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  height: 20px;
  padding: 0 !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.add-measurement-grid-row {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.add-measurement-grid-row > div {
  text-align: center;
  background-color: rgba(243, 245, 246, 1);
  padding: 10px 20px;
}

.measurement-col-row > div {
  background-color: white !important;
}

.d-flexY {
  display: flex;
  gap: 3px;
}

.d-flexY > input {
  padding: 0px 0px 0px 6px !important;
  width: 45px;
}

.measurement-col-row {
  position: relative;
  top: 0;
}

.measurement-col-row > div:nth-child(1) {
  background-color: transparent !important;
}

.measurement-col-row > div:nth-child(1) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.measurement-col-row > div:nth-child(1) > button {
  border-radius: 50px !important;
  background-color: rgba(255, 0, 0, 0.5) !important;
  width: 20px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  height: 20px;
  padding: 0 !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.create-fit-row-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 20px 0px;
}

.create-fit-row-cta > div > button {
  margin: 0px 10px;
  width: 150px;
  height: 48px;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.create-fit-row-cta > div > button:nth-child(1) {
  background-color: rgba(63, 74, 89, 1);
}

.create-fit-row-cta > div > button:nth-child(2) {
  background-color: rgba(51, 218, 189, 1);
}

.fitType-add-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fitType-add-cta > button {
  background-color: rgba(51, 218, 189, 1);
  height: 48px;
  width: 170px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.create-fit-row-grid,
.create-fit-row-col {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.create-fit-row-col .ant-select .ant-select-arrow {
  display: none !important;
}
