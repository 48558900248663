.request_add {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  padding: 25px;
}
.request_add_sec h2 {
  font-size: 30px;
  font-weight: 600;
  color: #1c1c1c;
  margin-bottom: 20px;
}
.request_add h6 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
  color: #1c1c1c;
}
.table_text h4 {
  font-size: 18px;
  font-weight: 700;
  color: #1c1c1c;
  padding: 20px 0px;
  margin-bottom: 0px;
}
.assign_timer h6 {
  font-size: 12px;
  font-weight: 700;
}
.assign_timer h6 span {
  font-size: 30px;
  font-weight: 700;
}
.assing_flex {
  display: flex;
  justify-content: space-between;
}
.assing_flex h2 {
  font-size: 30px;
  font-weight: 600;
}
.collar-flex {
  display: flex;
  flex-direction: row;
  width: 100%; /* Adjust width as needed */
}
.coller-column {
  width: 14.28%; /* 100% divided by 7 columns */
  height: 185px; /* Adjust height as needed */
  padding-top: 10px;
  border: 1px solid #ccc;
  text-align: center;
}
.cm {
  background-color: #ffb2b2;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.cm h6 {
  font-size: 17px;
  font-weight: 800;
  color: #1c1c1c;
  margin-bottom: 0px;
}
.coller-column p {
  font-size: 13px;
  font-weight: 500;
  color: #1c1c1c;
  margin-bottom: 0px;
  padding: 6px 0px 0px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  margin: 0 auto;
}
.collar-flex {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  overflow-x: scroll;
  overflow-y: hidden;
}
.target_complete h5 {
  font-size: 14px;
  font-weight: 700;
  color: #1c1c1c;
}
.target_complete span {
  font-size: 36px;
  font-weight: 700;
}
.border_center {
  background-color: #d6d6d6;
  width: 1px;
  height: 80px;
}
.target_complete h6 {
  font-size: 12px;
  font-weight: 700;
  color: #1c1c1c;
}
.remaining-count {
  background-color: #ddd;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -10px;
  z-index: 99;
}
/* .target_complete{
    padding-top: 15px;
} */
.target_but {
  margin-top: 6px;
  display: flex;
  margin: 0px 10px;
}
.target_but a {
  text-decoration: none;
  font-size: 10px;
  color: #1c1c1c;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #33dabd;
  border-radius: 15px;
  padding: 8px;
  cursor: default;
}
.text-ideals {
  color: #ff0000;
  font-weight: 700;
  font-size: 10px;
}
.coller-column .image-container .image-rbc {
  width: 32px;
  height: 32px;
}
.active_target {
  background-color: #ffe1e1;
  border: 1px solid #ff0000;
}
.parameters_current {
  max-width: 275px;
  min-width: 275px;
}

.coller-column.active {
  background: #ffe1e1;
}
.error-msg {
  color: #ff0000;
  font-size: 12px;
}
.select-label-bold {
  font-weight: 700;
  font-size: 13px;
  color: #1c1c1c;
  margin: 13px 0px 6px;
}
