body {
  margin: 0;
  font-family: Poppins;
}
.ant-picker-dropdown {
  z-index: 11111;
}
.react-daterange-picker__calendar {
  /* z-index: 1000; */
  width: 300px !important;
}
.react-flow__node.parent {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  /*background-color: #f7f7f773 !important;
  */
  background-color: transparent !important;
  border: 0px solid #d6d6d6 !important;
  height: 400px !important;
  z-index: -1 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.flow-section-category .react-flow__node.parent {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  /* background-color: #f7f7f773 !important; */
  background-color: #fff !important;
  border: 0px solid #d6d6d6 !important;
  min-height: 470px !important;
  z-index: -1 !important;
}
.react-flow__node.react-flow__node-group.nopan.light.selectable.parent {
  border: 0px solid #d6d6d6 !important;
  background-color: transparent !important;
}
.flow-section-category
  .react-flow__node.react-flow__node-group.nopan.selected.selectable.parent {
  box-shadow: 0 1px 8px 0 #33dabd, 0 3px 7px 0 #33dabd !important;
}
.selected.selectable .parent {
  border: 1px solid red !important;
}
/* .react-flow__selection{
  background-color: red !important;
} */
.text-updater-node {
  background-color: blue !important;
}

/* toggle-css */

.switch_slide {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 14px;
}

.switch_slide input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffb2b2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 1px;
  background-color: #da3333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #b2fff2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: #33dabd;
}

.hold_custom .switch_slide {
  width: 43px;
  left: 12px;
}
.hold_custom input:checked + .slider:before {
  left: 12px;
}
/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: black;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30%;
  font-size: 6px;
  font-weight: 600;
  font-family: Poppins;
}
.off {
  color: black;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 66%;
  font-size: 6px;
  font-weight: 600;
  font-family: Poppins;
}
input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ant-pagination .ant-pagination-options {
  display: none;
}
.audio_custom div {
  height: 60px !important;
  width: 100% !important;
}

/* category-flow-css */
.slick-prev:before {
  font-size: 0px;
  line-height: 1;
  opacity: 1;
  color: #fff;
  background-image: url("../public/Images/prev_icn.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
}
.slick-next:before {
  font-size: 0px;
  line-height: 1;
  opacity: 1;
  color: #fff;
  background-image: url("../public/Images/next_icn.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
}
.slick-next:before {
  content: url("../public/Images/next_icn.svg");
}
.slick-prev:before {
  content: url("../public/Images/prev_icn.svg");
}
.slick-prev {
  left: -16px;
  z-index: 99;
}
.slick-next {
  right: -9px;
}

/* custom select */
.custom-select-table {
  border-radius: 4px;
  border: 1px solid #1c1c1c42;
  width: 100%;
  padding: 0px 10px;
}
.selectArea {
  border-radius: 23px;
  width: 100%;
  height: 43.368px;
  border: 0px;
  /* background: #E0E0E0 !important; */
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  line-height: 16px;
  appearance: none;
  /* background: url(http://localhost:3000/static/media/Chevron.47c7334….svg) 96% / 15% no-repeat; */
}
.select_box_area .selectArea {
  background: url("../public/Images/Chevron.svg") 96% / 9% no-repeat;
}
.assign_select {
  background-size: auto !important;
}

.trail-title {
  font-weight: bold; /* Makes the text bold */
  font-size: 1.5em; /* Increases the font size by 1.5 times */
  background-color: white; /* Removes the black dots by setting a background color */
}
.react-flow__handle.react-flow__handle-top.nodrag.nopan.target.connectable.connectablestart.connectableend.connectionindicator {
  min-width: 0px;
  min-height: 0px;
  width: 0px;
  height: 0px;
  background: transparent !important;
  border: 1px solid transparent !important;
}
.react-flow__handle.react-flow__handle-bottom.nodrag.nopan.source.connectable.connectablestart.connectableend.connectionindicator {
  min-width: 0px;
  min-height: 0px;
  width: 0px;
  height: 0px;
  background: transparent !important;
  border: 1px solid transparent !important;
}
.react-flow__node.react-flow__node-default.nopan.selectable {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #1c1c1c;
  width: auto;
  margin-top: 13px !important;
}
.ant-select-focused .ant-select-placeholder {
  animation: shiftUp 0.2s ease-in-out forwards;
}

@keyframes shiftUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%); /* Adjust the amount of shift as needed */
  }
}
.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
  /* stroke-dashoffset: 50% !important;
  outline-offset: .6rem !important; */
  stroke: #0036f5 !important;
  /* stroke-dasharray: 40 !important;
  stroke-width: 5px !important; */
}
