.custom-calender {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #fff;
  /* overflow: auto;
   position: relative; */
}

/* .custom-calender::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-calender::-webkit-scrollbar-thumb
{
  background-color: #d5d5d5;
}
.rbc_event_body{
  overflow: auto;
}
.rbc_event_body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
} */
.rbc_event_body::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
}
.custom-calender .row > * {
  padding-right: calc(var(--bs-gutter-x) * 0);
  padding-left: calc(var(--bs-gutter-x) * 0.1);
}
.month-active {
  background: #33dabd;
  color: #3f4a59 !important;
}
.month-active h6 {
  color: #3f4a59;
}
.event-box {
  border-radius: 3px;
  border: 2px solid #29cc39;
  background: rgba(41, 204, 57, 0.05);
  padding: 8px;
  /* min-width: 200px; */
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
}
.rbc-event {
  color: #1c1c1c !important;
}
.rbc-month-row {
  overflow: visible !important;
}
.event-box h6 {
  color: #f00;
  font-size: 8px;
  font-weight: 600;
  margin-left: 7px;
  clear: both;
  overflow: hidden !important;
  white-space: nowrap;
}
.rbc-event-label {
  font-size: 57%;
  font-weight: 600;
}
.event-box a {
  border-radius: 10px;
  background: #f00;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  line-height: 16px; /* 200% */
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 62px;
  text-decoration: none;
}
.event-title {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.event-title img {
  width: 38px;
  height: 48px;
  border-radius: 4px;
}
.event-disc img {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.event-disc {
  display: flex;
  align-items: center;
}
.event-disc h5 {
  color: #1c1c1c;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px; /* 160% */
  margin-bottom: 0px;
  margin-left: 7px;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 0px auto #3b99fc !important;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 0px solid #265985;
  min-height: 170px !important;
  overflow: visible !important;
}
.rbc-btn-group {
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}
.rbc-btn-group > button:first-child {
  border-radius: 30px 0px 0px 30px;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
  background: var(--White, #fff);
}
.rbc-btn-group > button:last-child {
  border-radius: 0px 30px 30px 0px;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
}
.rbc-toolbar button {
  border: 2px solid var(--Grey-Blue-97, #f5f6f7) !important;
  background: var(--White, #fff) !important;
  color: #1c1c1c;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background: #33dabd !important;
}
.rbc-toolbar {
  justify-content: space-between !important;
  padding: 20px;
}
.rbc-month-header {
  min-height: 75px;
  border-top: 1px solid #ddd !important;
}
.rbc-header {
  /* padding-top: 27px !important; */
  color: #1c1c1c;
  text-align: center;
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16px; /* 133.333% */
}
.rbc-date-cell {
  text-align: center;
  min-height: 75px;
}
.rbc-button-link {
  /* display: none; */
  font-size: 13px;
  font-weight: 500;
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: 13px;
  font-weight: 600;
}

/* submission-screen */
.submission-form {
  background-color: #fff;
  padding: 40px;
}
.sub-input-box {
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.26);
  background: #fff;
  height: 48px;
  display: block;
  width: 100%;
  padding: 10px;
}
.upload-file-sub {
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.26);
  background: #fff;
  padding: 0px 10px;
}
.fa-upload {
  color: #d7d7d7;
  padding-top: 13px;
}
.custom_table_row {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  margin: 10px 0px;
  background: #fff;
  padding: 15px;
}
.custom_table_row i {
  cursor: pointer;
  margin-right: 10px;
}
.table_inside {
  padding: 10px 8px;
}
.form_but {
  border-radius: 4px;
  background: #33dabd;
  border: 0px;
  width: 152px;
  height: 44px;
  margin: 0 auto;
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
th {
  border-bottom: 0px;
}
.table_top {
  padding: 10px 0px;
}
.but_sec {
  text-align: center;
  padding: 30px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-event {
  /* position: static !important; */
  border: 0px !important;
}
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  white-space: unset !important;
}

/* custom-css */

/* src/components/CustomBigCalendar.css */
.calendar-grid {
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  font-size: 16px;
}

.calendar-header h2 {
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins;
}
.event {
  background-color: #4caf50;
  color: white;
  padding: 2px;
  margin-top: 2px;
}

.view-controls {
  display: inline-block;
  white-space: nowrap;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}

.view-controls button {
  border: 2px solid var(--Grey-Blue-97, #f5f6f7) !important;
  background: var(--White, #fff) !important;
  color: #1c1c1c;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  line-height: 16px;
}
.view-controls > button:first-child {
  border-radius: 30px 0px 0px 30px;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
  background: var(--White, #fff);
}
.view-controls > button:last-child {
  border-radius: 0px 30px 30px 0px;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
}

.view-controls button.active {
  background-color: #33dabd !important;
}
.left-view {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.left-view-btn {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px 20px;
  text-align: center;
  gap: 10px;
  border-radius: 20px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: 2px solid var(--Grey-Blue-97, #f5f6f7);
  background: var(--White, #fff);
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}

.left-view-btn-work {
  color: #33dabd;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 20px;
  cursor: pointer;
  border: 2px solid var(--Grey-Blue-97, #33dabd);
  background: var(--White, #fff);
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}
.left-view-btn-work a {
  text-decoration: none;
  color: #33dabd;
}
.rbc_event_body {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.week-view {
  position: relative;
  /* border: 1px solid #ddd; */
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.week-header {
  display: flex;
  flex-direction: row;
}
.day-label {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  min-height: 0;
  border: 1px solid #ddd;
  min-height: 100px;
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}
.day_view .day-label {
  min-width: 100px;
}
.day-label h6 {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0px;
  /* padding-top: 25%; */
}
.day-body {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;

  height: 100%;
}
/* .day-body-inside{
  display: flex;
    flex-direction: row;
    flex: 1 0;
    overflow: hidden;
} */
.day {
  flex: 1 0;
  border: 1px solid #ddd;
  min-height: 130px;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--Grey-Blue-80, #adb8cc);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.day_sun {
  flex: 1 0;
  border: 1px solid #ddd;
  min-height: 130px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--Grey-Blue-80, #adb8cc);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* min-width: 100px; */
  min-height: 110px;
}
.day_sun.event {
  box-sizing: border-box;
}
.day,
.event {
  box-sizing: border-box;
}
.rbc-row {
  box-sizing: border-box;
  min-height: 20px;
  position: relative;
}
.more_user {
  border-radius: 10px;
  background: #f00;
  color: #fff;
  font-family: Poppins;
  font-size: 8px;
  font-weight: 600;
  line-height: 16px;
  max-width: 48px;
  min-width: 48px;
  height: 16px;
  margin: 0 auto;
  cursor: pointer;
}
.overLap_text {
  width: 21px;
  height: 21px;
  border: 1px solid #fff;
  color: #3f4a59;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  background-color: #33dabd;
  border-radius: 50%;
  margin-left: -7px;
  z-index: 7;
}
.day.sunday-box {
  min-height: 185px;
  max-height: 185px;
}
.image-container {
  justify-content: center;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.gallery_top {
  margin-top: 2px;
  margin-left: 4px;
}
.image-rbc {
  width: 20px;
  height: 20px;
  margin: 0 0px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #fff;
}

.image-rbc:nth-child(2) {
  z-index: 1; /* Make the second image appear on top */
  margin-left: -7px; /* Overlap the first image by 20px */
}

.image-rbc:nth-child(3) {
  z-index: 2; /* Make the third image appear on top of the second */
  margin-left: -7px; /* Overlap the second and first images by 40px */
}
.image-rbc:nth-child(4) {
  z-index: 3; /* Make the third image appear on top of the second */
  margin-left: -7px; /* Overlap the second and first images by 40px */
}
.image-rbc:nth-child(5) {
  z-index: 4; /* Make the third image appear on top of the second */
  margin-left: -7px; /* Overlap the second and first images by 40px */
}
.image-rbc:nth-child(6) {
  z-index: 5; /* Make the third image appear on top of the second */
  margin-left: -7px; /* Overlap the second and first images by 40px */
}
.custom-big-calendar {
  position: relative;
}
.calendar_sidebar_box {
  border-radius: 16px 4px 4px 16px;
  background: #fff;

  box-shadow: -24px 24px 48px 0px rgba(0, 0, 0, 0.16);
  height: 100vh;
  min-width: 419px;
  max-width: 419px;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 12;
  padding: 20px 18px;
  height: 700px;
}
.close_calendr-box {
  position: absolute;
  left: -10px;
  top: 8%;
  z-index: 14;
}
.header-wrapper img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.accordion-headerr {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  /* border: 1px solid #E6E6E6;
    padding: 10px; */
  /* margin-bottom: 10px; */
}
.header-wrapper span {
  color: #1c1c1c;
  text-transform: capitalize !important;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  margin-left: 5px;
}
.arrow_size {
  width: 18px;
  height: 18px;
}
.prev_icn {
  position: absolute;
  left: -15px;
  top: 18px;
}
.next_icn {
  position: absolute;
  right: 0px;
  top: 18px;
}
.prev_icn_black {
  position: absolute;
  left: 12%;
  top: 40%;
}
.next_icn_black {
  position: absolute;
  right: 0px;
  top: 40%;
}
.main-box {
  position: absolute;
  top: 0;
  height: auto;
  left: 0;
  width: 100%;
  z-index: 1;
}
.task-box {
  border: 2px solid var(--Green, #29cc39);
  background: var(--green-5, rgba(41, 204, 57, 0.05));
  min-width: 162px;
  max-width: 162px;
  min-height: 180px;
  max-height: 180px;
}
.day_tsak_box {
  border: 2px solid var(--Green, #29cc39);
  background: var(--green-5, rgba(41, 204, 57, 0.05));
  min-width: 130px;
  max-width: 130px;
  min-height: 180px;
  max-height: 180px;
}
.day_tsak_box .tooltip_rbc .tooltiptext {
  width: 120px;
  top: 121%;
  left: 18%;
  font-size: 10px;
  padding: 9px;
}

.img-box {
  width: 25px;
  height: 25px;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}
.product_image {
  width: 38px;
  height: 38px !important;
  border-radius: 4px;
  margin-right: 4px;
  object-fit: cover;
  object-position: top center;
  overflow-clip-margin: unset;
  overflow: hidden;
}
.task {
  padding: 5px 10px;
  align-items: center;
}
.task img {
  margin-right: 10px;
}
.task p {
  /* color: #ff0000 !important; */
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  cursor: pointer;
}
.admin_name {
  padding: 0px 10px;
}
.admin_name h6 {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 5px;
  text-align: left;
  white-space: initial;
  text-transform: capitalize;
  flex: 0.8;
}
.img-box {
  border-radius: 7px;
}

.box_body {
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* height: 600px; */
}

.box_body > .accordion {
  height: 480px !important;
  overflow-y: scroll;
}

.box_body::-webkit-scrollbar {
  width: 8px;
}
.box_body::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 5px;
}
.tooltip_rbc {
  position: relative;
  /* display: inline-block; */
  height: 30px;
  /* width: 30px; */
  flex: 0.85;
  /* border-bottom: 1px dotted black; */
}

.tooltip_rbc .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #3f4a59;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 10;
  top: 80%;
  left: 0%;
  margin-left: -60px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}

.tooltip_rbc .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 11px;
  border-style: solid;
  border-color: transparent transparent #3f4a59 transparent;
}

.tooltip_rbc:hover .tooltiptext {
  visibility: visible;
}

.slick-slide.slick-active.slick-center.slick-current .month-box {
  background-color: #33dabd;
}
.slick-slide.slick-active.slick-center.slick-current .month-box h6 {
  color: #3f4a59;
}
.header-wrapper h6 {
  color: #f00;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 3px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-top: 5px;
}
.header-wrapper small {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 3px;
  margin-left: 6px;
}
.accordion-items {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
}
/* .main-box-week{
  margin: 0px 10px;
} */

@media (min-resolution: 130dpi) and (max-resolution: 150dpi) {
  .task-box {
    min-width: 145px;
    max-width: 147px;
  }
}
@media (min-resolution: 140dpi) and (max-resolution: 150dpi) {
  .task-box {
    min-width: 140px;
    max-width: 140px;
  }
}
.boder_right {
  height: 19px;
  width: 7px;
  border-right: 1px solid #ffffff33;
  margin: 0px 10px;
}
.header_icon {
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
}
.header_icon h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
}
.text_input {
  height: 48px;
  border-radius: 4px;
  font-size: 12px;
  color: #1c1c1c;
  background-color: #fff;
  border: 0px;
  width: 100%;
  margin-top: 30px;
  padding: 15px 10px;
}
