.fit-variations {
  background-color: white;
  padding: 30px 30px;
  border: 1px solid rgba(214, 214, 214, 1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  > h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
