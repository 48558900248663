.node_box {
  border-radius: 4px;
  /* border: 1px solid #D6D6D6; */
  padding: 6px 1px 23px 1px;
  background: #fff;
}
.node-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.node-im {
  position: relative;
  max-width: 84px;
  min-width: 84px;
  height: 102px;
}
.node-im img {
  border-radius: 4px;
}
.node-im h6 {
  color: #1c1c1c;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.close_icon {
  position: absolute;
  right: 0px;
}
.blue_but {
  border: none;
  border-radius: 4px;
  background: #0036f5;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 700;
  /* max-width: 156px;
min-width: 156px; */
  /* height: 40px; */
  text-decoration: none;
  padding: 10px 15px;
  margin-right: 7px;
}
.blue_but:hover {
  text-decoration: none;
  color: #fff;
}
.green_but {
  border-radius: 4px;
  background: #33dabd;
  color: #1c1c1c;
  font-family: Poppins;
  border: none;
  font-size: 12px;
  font-weight: 700 !important;
  max-width: 156px;
  min-width: 156px;
  /* height: 40px; */
  text-decoration: none;
  padding: 10px 15px;
}
.green_but:hover {
  text-decoration: none;
  color: #1c1c1c;
}
.container_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  color: #1c1c1c;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #c4cbcf;
}

/* On mouse-over, add a grey background color */
.container_checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container_checkbox input:checked ~ .checkmark {
  background-color: #33dabd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container_checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container_checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid #1c1c1c;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.number_size h6 {
  padding-top: 6px;
}

.gray_box_input {
  border-radius: 4px;
  background: #f3f5f6;
  height: 48px;
}
.node_box label {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
}
.gray_box_input p {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  padding: 14px 10px;
}
.gray_box_input h5 {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 14px;
  padding: 14px 10px;
  font-weight: 800;
  line-height: 16px; /* 114.286% */
}
.play_but {
  font-size: 15px;
  width: 40px;
  height: 40px;
  background-color: #37b99c5c;
  color: #1c1c1c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.node-im img.main-img {
  width: 80px;
  height: 100px;
  object-fit: cover;
  overflow-clip-margin: unset;
  overflow: hidden;
}
.hold_switch {
  display: flex;
  justify-content: space-between;
  align-items: initial;
  border-bottom: 1px solid #d6d6d629;
  padding-right: 10px;
}
.flow_tooltip {
  text-align: left !important;
}
.flow_tooltip a {
  margin: 0px 5px !important;
}
