.loginBg {
  min-height: 100%;
  background: url("../../../public/Images/login-bg.svg");
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
}
.login-form {
  position: relative;
  top: 0;
  max-width: 360px;
  min-width: 356px;
  /* min-height: 447px;
    max-height: 447px; */
  margin: 0 auto;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 16px 35px 0px rgba(0, 0, 0, 0.1), 0px 64px 64px 0px rgba(0, 0, 0, 0.09),
    0px 143px 86px 0px rgba(0, 0, 0, 0.05),
    0px 255px 102px 0px rgba(0, 0, 0, 0.01),
    0px 398px 111px 0px rgba(0, 0, 0, 0);
  padding: 22px;
}
.logo-im img {
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 15px 0px;
}
.input-box {
  border-radius: 2px;
  background: #f3f5f6;
  height: 48px;
  border: 0px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}
.login-form h1 {
  color: #1c1c1c;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.open__uri__modal {
  position: absolute;
  top: -20px;
  left: -20px;
}

.open__uri__modal > button {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #33dabd !important;
  color: white !important;
}

.login-form small {
  color: #1c1c1c;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  display: block;
}
.login-form label {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 400;
}
.login-but {
  border-radius: 4px;
  background: #33dabd;
  border: 0px;
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 154px;
  min-width: 154px;
  height: 48px;
  display: block;
  margin: 0 auto;
}
.login-form span {
  font-size: 10px;
  color: red;
}
.logout-profile button {
  border: 0px;
  background-color: transparent;
  color: #1c1c1c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 12px;
}

.base__uri__modal__container > button {
  width: 100%;
  background-color: #33dabd;
  height: 48px;
  margin-top: 15px;
}

.label-row {
  font-weight: 600;
  font-size: 14px;
}

.base__uri__low {
  font-size: 11px;
  margin-top: 10px;
  background-color: #33dabd;
  padding: 2px 10px;
  font-weight: 600;
  color: #1c1c1c;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin-bottom: 0 !important;
}
