* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  font-family: Poppins;
  letter-spacing: 0.5px;
  scrollbar-width: 12px;
}

body,
html {
  overflow-x: hidden;
}

.container-fluid > div > div:nth-child(2) {
  width: 80%;
  margin: auto !important;
}

@media screen and (min-width: 1700px) {
  .container-fluid > div > div:nth-child(2) {
    width: 1200px !important;
  }
}

.header-bg > div:nth-child(1) > svg {
  display: none;
}

@media screen and (max-width: 1360px) {
  .sidebar_bg {
    position: fixed;
    /* display: none; */
    transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    -moz-transform: translateX(-500px);
    -ms-transform: translateX(-500px);
    -o-transform: translateX(-500px);
    z-index: 99999;
    transition: 0.3s ease-in;
    -webkit-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -ms-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
  }
  .container-fluid > div > div:nth-child(2) {
    width: 100% !important;
  }
  .sidebar-open > #root > .container-fluid > .d-flex > .sidebar_bg {
    /* display: block; */
    transition: 0.3s ease-in;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
  }

  .header-bg > div:nth-child(1) > svg {
    display: block;
  }
}

button,
a {
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.ant-select-selector {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.ay-persion-flex > .d-flex {
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
}

.close-icon > img {
  width: 40px !important;
}

.response-flex-row > .d-flex {
  gap: 10px;
}

.scale-icons > button {
  background-color: transparent;
  border: none;
  border: 1px solid #c7c7c7;
  margin: 0px 10px 20px 0px;
}

.clear-cicons {
  border: none;
  background-color: transparent;
  color: rgba(255, 0, 0, 1);
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.clear-cicons:active {
  opacity: 0.4;
}

input,
select,
textarea {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.workload-block > .d-flex {
  gap: 10px;
  padding: 5px;
}

.tab-module-row-settings {
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
}

.ant-select-selection-placeholder {
  color: rgba(28, 28, 28, 1) !important;
  font-weight: 700 !important;
}

button {
  font-weight: 600 !important;
}

.sample-request-add {
  border: 1px solid rgba(214, 214, 214, 1) !important;
}

.new-download > a {
  text-decoration: none !important;
}

.new-download {
  text-align: center;
}

.audio-icon-play {
  border: none;
  outline: none;
  background-color: transparent;
}

.ant-pagination-item-link {
  height: 30px !important;
  display: block !important;
}

.modal-content {
  border: none !important;
}

.upload-file > aside > div {
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.remark-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 48px !important;
}

.loader_icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 0px 80px 0px;
}

.dark-but {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-style {
  width: 100%;
  height: 48px;
  outline: none;
  font-size: 12px;
  padding: 8px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.create-node-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ant-select-selection-overflow {
  height: 46px !important;
  overflow-y: scroll;
}

.ant-select-selection-overflow::-webkit-scrollbar {
  display: none;
}

.ant-select-selection-overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.download-but-excel > button:nth-child(1) {
  background-color: rgba(0, 54, 245, 1) !important;
  color: white !important;
}

.heading-row-master-fit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.drop-zone-button > span {
  color: white;
}

.image-icons {
  width: 50px;
  /* mix-blend-mode: hard-light; */
  overflow-clip-margin: unset;
  overflow: hidden;
}

.cente-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* xxxxxxxxxxxxx */

.fitType-add-cta > button,
.create-fit-row-cta > div > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-fit-row-cta > div {
  display: flex;
}

.text-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.node-auto-submission {
  position: relative;
  top: 0;
}

.subtract-button {
  position: absolute;
  left: -25px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.subtract-button > button,
.delete-button > button {
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-button > button {
  margin-top: 30px;
  background-color: #b43f3f !important;
  color: white;
}

.input-disabled {
  height: 48px !important;
  padding: 0px 15px;
  border-radius: 5px;
  background-color: rgba(234, 233, 233, 0.4);
  font-size: 14px;
  width: 100%;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.submit-cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > button {
    background-color: rgba(51, 218, 189, 1);
    width: 140px;
    font-weight: 700 !important;
    text-transform: uppercase;
    border-radius: 4px !important;
    height: 48px;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
  }
}

.over-scroll-row > div:nth-child(1) {
  width: 100%;
  overflow-x: auto;
  /* overflow-y: hidden; */
}

.new-table-wrap {
  border-bottom: none !important;
}

.new-table-wrap > div {
  justify-content: center !important;
  border-bottom: 1px solid #d6d6d6 !important;
}

.current-nodes-msg,
.current-nodes-msgs {
  width: 40px !important;
  margin: auto;
  position: relative;
  top: 0;
}

.msg-notifications {
  left: -5px !important;
}

.new-notify {
  left: -5px !important;
}

.image-view-modal .modal-heading {
  align-items: center !important;
}

.image-view-modal h4 {
  font-size: 22px;
  font-weight: 600;
  color: rgba(28, 28, 28, 1);
}

.image-view-modal .modal-dialog {
  max-width: 720px !important;
}

.image-view-grid-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-view-grid-row > div > img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  object-position: top;
  overflow-clip-margin: unset;
  overflow: hidden;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.clear-btn-action {
  font-size: 14px;
  cursor: pointer;
}

.reference-url-row > a {
  text-decoration: none;
  font-size: 12px;
  margin-top: 20px;
  color: rgba(28, 28, 28, 1);
}

.clear-btn-action > span {
  border-bottom: 1px solid #c7c7c7;
}

.hours > div:nth-child(4) {
  border-bottom: none;
}

.Image-row-submission {
  width: 100%;
}

.ml-left {
  margin-left: 10px;
}

.from-to-date {
  position: relative;
  top: 0;
}

.from-to-date > label {
  position: absolute;
  top: -25px;
}

.radio-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.disabled-audio {
  /* background-color: red !important; */
  opacity: 0.4;
}

.disabled-audio > i {
  color: white;
}

.input-parameter-relation {
  width: 100%;
  height: 48px;
  border: 1px solid #d9d9d9;
  padding: 0px 8px;
  font-size: 12px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.calendar_sidebar_box > input {
  height: 40px;
  border-radius: 50px;
  padding: 2px 20px;
  background-color: rgba(235, 235, 235, 1);
  border: none;
  width: 80%;
  margin: auto;
  display: flex;
  color: rgba(153, 153, 153, 1);
  font-weight: 400 !important;
  font-size: 14px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.view__more {
  max-width: 70px !important;
  min-width: 70px !important;
}

.right-side-cta {
  white-space: nowrap;
}

.right-side-cta > button,
.right-side-cta > a {
  font-size: 14px;
}

.user-name {
  font-size: 9px;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 8px;
  font-weight: 600;
  text-align: center;
}

.submit-cta > button {
  background-color: #33dabd;
  height: 38px;
  border: none;
  margin-top: 0px !important;
  color: white;
}

/* Collections Row css starts here */
.heading-collections-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.heading-collections-row > div > button {
  background-color: rgba(51, 218, 189, 1);
  padding: 0px 25px;
  height: 44px;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.heading-collections-row > div > h1 {
  font-size: 30px;
  color: rgba(28, 28, 28, 1);
  font-weight: 600;
}

.collection-modal-sample > form > div > label {
  font-weight: 700;
  color: rgba(28, 28, 28, 1);
  font-size: 14px;
  margin-top: 20px;
}

.collection-modal-sample > form > div > input {
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.26);
  outline: none;
  height: 44px;
  font-size: 14px;
  margin-top: 5px;
  padding: 0px 10px;
}
/* Collections Row css ends here */

.react-calendar button:disabled {
  /* background-color: red !important; */
  opacity: 0.2 !important;
}

.chart-line-bar-container {
  max-width: 100%;
  overflow-x: scroll;
}

.accordion-body {
  padding: 1rem 10px;
}

.accordion-row-week {
  max-width: fit-content;
  gap: 15px;
}

.pattern-design {
  margin: 10px 0px;
}

.yellow-border > span {
  margin-right: 5px;
}

.bg-white {
  background-color: white !important;
  padding: 20px 30px;
  margin-top: 30px;
  border: 1px solid rgba(214, 214, 214, 1);
  margin-top: 20px;
}

.bg-white > h3 {
  color: rgba(28, 28, 28, 1);
  font-weight: 600;
  font-size: 26px;
}

.upload-pdf-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.garment-create-fit-container > .row {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 10px !important;
}

.button-flow-js {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.button-flow-js > button {
  background-color: rgba(51, 218, 189, 1);
  height: 44px;
  width: 152px !important;
  text-transform: uppercase !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .selectbox-filter-row {
  min-height: 140px;
} */

.user-data-col-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.extend-timeline-row {
  background-color: rgba(28, 28, 28, 1);
  /* height: 33px; */
  color: white;
  font-size: 11px !important;
  padding: 3px 20px !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.extend-hours-container {
  margin-top: 20px;
}

.extend-hours-container > label {
  font-size: 14px;
  color: rgba(28, 28, 28, 1);
  font-weight: 700;
}

.extend-hours-container > input {
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.26);
  height: 48px;
  outline: none;
  padding: 2px 10px;
  border-radius: 4px;
  margin-top: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.extend-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.extend-cta > button {
  background-color: rgba(51, 218, 189, 1);
  height: 44px;
  width: 152px !important;
  text-transform: uppercase !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sample-request-nodes {
  gap: 10px !important;
}

.sample-request-nodes > h2 {
  font-size: 26px !important;
  font-weight: 600 !important;
}

.d-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.d-grid-modal-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.date-picker-node-target {
  border-radius: 4px;
  background: #fff;
  height: 48px;
  padding: 12px;
  width: 100%;
  border: 0px;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.date-picker-row-node-target > .react-datepicker-wrapper {
  width: 100% !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.react-daterange-picker__wrapper {
  padding: 5px !important;
}

.heading-select-row {
  gap: 10px !important;
}

.heading-select-row > h2 {
  font-size: 26px;
  font-weight: 600;
}

.rejection-submission-cta-row {
  white-space: nowrap;
  margin-bottom: 8px !important;
}

.gallery-grid-row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 10px !important;
}

/* .list_flex {
  gap: 30px !important;
} */

.user-all-pending-row {
  display: flex !important;
  gap: 25px !important;
  margin: auto !important;
  flex-direction: row !important;
  overflow-x: auto !important;
}

@media screen and (min-width: 1361px) {
  .user-all-pending-row {
    max-width: 980px !important;
  }
}

.red-prefilled-row {
  color: #00bb5a;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0rem !important;
  margin-top: 5px !important;
}

.no-data {
  text-align: center;
  padding: 40px 0px;
}

.no-data > h1 {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0rem;
}

.no-data > p {
  font-size: 14px;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.checkbox-grid-row > .d-flex {
  gap: 15px;
}

.react-daterange-picker__wrapper {
  border: thin solid #f7f7f7 !important;
}

.h4-lead-time {
  font-size: 14px;
  font-weight: 600;
}

.d-flex-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
