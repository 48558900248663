.master-fitsc-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.master-fitsc-row > div > h2 {
  color: #1c1c1c;
  font-size: 28px;
  font-weight: 600;
}

.master-fitsc-row > div > button {
  background-color: rgba(0, 54, 245, 1) !important;
  color: white !important;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 44px;
}

.edit-cta {
  background-color: rgba(98, 133, 255, 1) !important;
  text-transform: capitalize !important;
  width: 115px;
  font-size: 12px;
  color: rgba(28, 28, 28, 1);
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}
