.select-addition-wrapper,
.color-variation-wrapper,
.Product-data-row,
.table-row-component,
.select-box-wrapper-row {
  background-color: white;
  padding: 40px 20px;
  margin: 20px 0px 20px 0px;
  border: 1px solid rgba(214, 214, 214, 1);
}

.Product-data-row,
.table-row-component {
  padding: 20px 20px !important;
}

.select-addition-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.labelx {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
}

.select-addition-grid-row > div > input,
.input-checkbox {
  width: 100%;
  background-color: rgba(243, 245, 246, 1);
  height: 48px;
  padding: 0px 10px;
  font-size: 12px;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.input-checkbox {
  background-color: transparent !important;
  border: 1px solid rgba(28, 28, 28, 0.26) !important;
}

.color-variation-grid-row {
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 30px;
}

/* .color-variation-grid-row > div:nth-child(1) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
} */
.color-variation-grid-row > div:nth-child(1) > div > div button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 18px !important;
  height: 18px !important;
  padding: 10px;
  background-color: rgba(28, 28, 28, 1);
  color: white;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.color-variation-grid-row > div:nth-child(1) > div > dvi > button > span {
  color: white;
  font-size: 11px;
}

.color-variation-grid-row > div:nth-child(1) > input {
  width: 100%;
  margin-top: 5px;
  height: 48px;
  font-size: 14px;
  border: 1px solid rgba(28, 28, 28, 0.26);
  padding: 0px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.upload-images {
  max-width: 90px !important;
  height: 100px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-zone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(63, 74, 89, 1);
  width: 18px;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

/* .dropzone-row-wrapper {
  max-width: 100px !important;
} */

.dropzone-row-wrapper > div {
  display: grid;
  grid-template-columns: 0.6fr 3fr;
  gap: 10px;
}

.aside-grid-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.image-color-addition-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-color-addition-cta > button {
  background-color: rgba(51, 218, 189, 1);
  height: 48px;
  width: 140px;
  font-family: "Poppins";
  font-weight: 600 !important;
}

.selecting-checkbox-wrapper {
  background-color: white;
  border: 1px solid rgba(214, 214, 214, 1);
  padding: 40px 20px 40px 20px;
}

.color-variation-grid-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.color-variation-grid-row > div {
  height: auto;
}

.checkbox-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 10px;
}

.checkbox-grid-row > div > div > input,
.batch-input {
  margin-top: 5px;
  height: 48px;
  width: 100%;
  background-color: rgba(243, 245, 246, 1);
  border: none;
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 50px;
  height: 15px;
  border: none;
  border-radius: 2px;
  background-color: #dcdcdc;
  cursor: pointer;
}

input[type="checkbox"] + label::before {
  content: "\2713";
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: white;
  opacity: 0;
}

input[type="checkbox"]:checked + label {
  background-color: rgba(51, 218, 189, 1);
}

input[type="checkbox"]:checked + label::before {
  opacity: 1;
  display: none;
}

.images-grid-row-checkbox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.images-grid-row-checkbox > img {
  width: 80px;
  border: 1px solid rgba(214, 214, 214, 0.4);
  padding: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.product-data-grid-row {
  display: grid;
  grid-template-columns: 1fr 0.2fr 4fr;
  gap: 10px;
  border-bottom: 1px solid rgba(214, 214, 214, 1);
  padding-bottom: 20px;
  padding-top: 20px;
}

.product-data-grid-row > div {
  font-size: 12px !important;
  font-family: "Poppins";
  font-weight: 700 !important;
}

.product-data-grid-row:last-child {
  border-bottom: none;
}

.preview-sku-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.preview-sku-cta > button:nth-child(1) {
  background-color: rgba(63, 74, 89, 1);
  height: 48px;
  width: 180px;
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preview-sku-cta > button:nth-child(2) {
  background-color: rgba(51, 218, 189, 1);
  height: 48px;
  width: 280px !important;
  padding: 0px 80px !important;
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-row-component > h2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 26px;
}

.select-box-batch-level-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.border-none {
  border: 1px solid rgba(28, 28, 28, 0.26) !important;
  background-color: transparent !important;
}

.size-arr-map {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.color-size-grid-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  margin-top: 20px;
}

.inc-wdth > button {
  width: 280px !important;
}

.image-color-addition-cta > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.render-thumbs-row > div {
  position: relative;
  top: 0;
}

.render-thumbs-row > div > button {
  position: absolute !important;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 20px !important;
  padding: 4px 3px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px !important;
  /* border-radius: 0px 0px 0px 10px !important; */
  background-color: rgba(51, 218, 189, 1) !important;
}

.render-thumbs-row > div > button > svg {
  color: #1c1c1c;
}

.checkbox-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.checkbox-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #ddd;
  transition: 0.3s;
}

.checkbox-btn span::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 8px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  transform: scale(0) rotate(-45deg);
  position: relative;
  top: 0px;
  bottom: 4px;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
  -webkit-transform: scale(0) rotate(-45deg);
  -moz-transform: scale(0) rotate(-45deg);
  -ms-transform: scale(0) rotate(-45deg);
  -o-transform: scale(0) rotate(-45deg);
}

.checkbox-btn input {
  display: none;
}

.checkbox-btn input:checked ~ span {
  background-color: #02bcf0;
}

.checkbox-btn input:checked ~ span::before {
  transform: scale(1) rotate(-45deg);
}
