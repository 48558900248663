table th {
  font-family: "Poppins" !important;
  font-weight: 900;
  font-size: 12px !important;
  color: rgba(1, 18, 34, 0.8) !important;
  line-height: 1.3195 !important;
  text-align: center !important;
}

table td {
  font-size: 11px !important;
  color: rgba(1, 18, 34, 1) !important;
  font-family: "Poppins" !important;
  font-weight: 600;
  text-align: center !important;
}

._9w8d5 {
  font-family: "Poppins" !important;
  font-weight: 900;
  font-size: 12px !important;
  color: rgba(1, 18, 34, 0.8) !important;
}

.MuiTableCell-root {
  border-bottom: transparent !important;
}

.MuiTableRow-head {
  background-color: rgba(243, 245, 246, 1) !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
  color: #1c1c1c !important;
}

.MuiTableCell-body,
.MuiTableCell-head {
  height: 70px !important;
  margin-bottom: 20px !important;
}

.MuiTableCell-head {
  padding: 0px 30px !important;
}

.MuiTableCell-body {
  border-bottom: 1px solid #ddd !important;
}

.MuiTableCell-head:nth-child(1) {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.MuiTableCell-head:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.table-container-wrapper td {
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 12px !important;
  border-bottom: 1px solid rgba(230, 233, 242, 1) !important;
}

.table-wrapper-pagination {
  overflow-x: auto;
  white-space: nowrap;
  background-color: white;
  padding: 30px 20px;
}

.table-container-pagination {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.garment-cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.garment-cta-container > button {
  font-family: "Poppins";
  font-size: 12px;
  color: #1c1c1c;
  font-weight: 600;
  padding: 6px 30px !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.garment-cta-container > button:hover {
  color: #1c1c1c !important;
}

.garment-cta-container > button:nth-child(1) {
  background-color: rgba(98, 133, 255, 1);
}

.garment-cta-container > button:nth-child(2) {
  background-color: rgba(51, 218, 189, 1);
}

.garment-cta-container > button:nth-child(3) {
  background-color: rgba(198, 175, 249, 1);
}

.MuiTableCell-root {
  font-weight: 600 !important;
}

.garment-fit-show > td > div {
  background-color: rgba(243, 245, 246, 1) !important;
  padding: 40px 10px !important;
  margin: 20px 20px;
}

.variation-row {
  border-spacing: 0px !important;
  border-collapse: separate !important;
}
