.custom-select {
  border-radius: 23px;
  background: #e0e0e0;
  width: 13%;

  padding: 0px 10px;
}
.heading_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
.heading_select h2 {
  color: #1f1f1f;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
}

.heading_select h1 {
  color: #1f1f1f;
  font-family: Poppins;
  font-size: 32px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-weight: 600;
}

.selectArea {
  border-radius: 23px;
  width: 100%;
  height: 43.368px;
  border: 0px;
  /* background: #E0E0E0 !important; */
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  line-height: 16px; /* 114.286% */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../public/Images/Chevron.svg") 96% / 15% no-repeat;
}

/* CAUTION: Internet Explorer hackery ahead */

select::-ms-expand {
  display: none !important; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9 !important;
    padding: 5px\9;
  }
}
.wordload-card {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #fff;
  padding: 17px;
  margin: 20px 0px;
}
.border-wordload {
  border-bottom: 1px solid #d6d6d6;
  margin: 20px 0px;
}
.word-img {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  /* overflow: hidden; */
  border-radius: 50%;
}
.word-img img {
  width: 100%;
  height: 60px;
  border-radius: 50%;
}
.word-green .word-status {
  background-color: #f00;
}
.word-green {
  border: 2px solid #f00;
}
.word-status {
  position: absolute;
  bottom: -5px;
  z-index: 1;
  padding: 7px;
  text-align: center;
  color: #fff;
  font-size: 7px;
  font-weight: 600;
  line-height: 9px;
  border: 1px solid #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 40%;
  margin: 0 auto;
}
.word-status span {
  width: 67px;
  display: block;
  margin: 0 auto;
}
.word-colum {
  max-width: 75px;
  min-width: 75px;
  /* margin: 0 auto; */
  margin-right: 20px;
}
.word-title {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  padding: 0px 0px 0px 10px;
  text-align: center;
  line-height: 12px; /* 120% */
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}
.word-name {
  margin-bottom: 5px;
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  line-height: 12px; /* 120% */
  /* clear: both;
    overflow: hidden;
    white-space: nowrap;  */
  padding-top: 7px;
}
.word-row {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.node_wise_graph h6 {
  color: var(--neutral-colors-400, #9291a5);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
}
.apexcharts-toolbar {
  display: none;
}
.word-colum .user_image_box {
  font-size: 15px;
}
.user_im_w .user_image_box {
  font-size: 15px;
}
.all-pending-employee .user_image_box {
  font-size: 12px;
}
.all-pending-list .user_image_box {
  font-size: 15px;
}
.fllter-con .user_image_box {
  font-size: 15px;
}
.persion-flex .user_image_box {
  font-size: 12px;
}
.current {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 174px;
  min-width: 174px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  height: 54px;
}
.information {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 174px;
  min-width: 174px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  height: 54px;
}
.screen-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  max-width: 200px;
  min-width: 200px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}
.tab_setting {
  padding-left: 0px;
  margin-bottom: 0px;
}
.tab_body {
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  background: #fff;
  padding: 10px;
}
.buffer_input {
  border: 1px solid #1c1c1c42;
  padding: 10px;
  height: 42px;
  color: #1c1c1c42;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
}
.view-but-gray a {
  background-color: #3f4a59;
  color: #ffffff !important;
  font-size: 15px;
  font-weight: 700;
  font-family: Poppins;
  border-radius: 4px;
  padding: 12px 26px;
  text-decoration: none;
}
.view_a_red a {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background-color: #ff0000;
  text-decoration: none;
  padding: 4px 16px;
  border-radius: 12px;
}
.view_a_green a {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background-color: #2ed47e;
  text-decoration: none;
  border-radius: 12px;
  padding: 4px 12px;
}
.logs-but a {
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  padding: 4px 6px;
  border-radius: 10px;
  text-decoration: none;
}
.NodeActive {
  background-color: #2ed47e;
}
.NodeDeactive {
  background-color: #ff0000;
}
.NodeHold {
  background-color: #9291a5;
}
.diseble_play i {
  font-size: 37px;
  opacity: 0.1;
}
.download_file {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #0036f5;
}
.up_file {
  text-align: left;
}
