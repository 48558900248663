.ideal-consumption-wrapper {
  background-color: white;
  padding: 30px 30px;
  border: 1px solid rgba(214, 214, 214, 1);
}

.grid-ideal-consumption-row {
  display: grid;
  grid-template-columns: 200px 4fr;
  gap: 20px;
}

.ideal-consumption-row {
  border-bottom: 1px solid rgba(214, 214, 214, 1);
  padding-bottom: 20px;
}

/* 
.grid-ideal-consumption-row > div {
  border: 1px solid black;
  height: 100px;
} */

.grid-ideal-consumption-row {
  display: grid;
  grid-template-columns: 2fr 4fr;
  margin-top: 40px;
}

.ideal-consumption-grid-row {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
}

.ideal-img-grid-row > div:nth-child(1) > img {
  width: 100%;
  border-radius: 7px;
  height: 167px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.ideal-img-grid-row > div:nth-child(1) {
  position: relative;
  top: 0;
}

.product-name-ideal-consumption {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product-name-ideal-consumption > p {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  color: white;
  padding: 4px 15px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.ideal-img-grid-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.redirect-file {
  margin-top: 0px !important;
  height: 167px !important;
}

.consumption-row > h5 {
  color: rgba(63, 74, 89, 1) !important;
  font-size: 14px;
}

.images-add-consumption-grid-row {
  margin-top: 10px;
  background-color: white;
  padding: 30px 30px;
  border: 1px solid rgba(214, 214, 214, 1);
}

.add-ideal-consumption-grid-row {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 20px;
}

.add-ideal-consumption-grid-row > div > img {
  width: 100%;
  height: 174px;
  transition: 0.3s ease-in-out;
  object-fit: cover;
  object-position: top;
  border: 1px solid transparent;
  overflow-clip-margin: unset;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.add-ideal-consumption-grid-row > div > p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
}

.add-ideal-consumption-grid-col {
  position: relative;
  top: 0;
}

.add-ideal-consumption-grid-col > div {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 54, 245, 1);
  width: 21px;
  height: 21px;
  border-radius: 4px 0px 4px 0px;
  -webkit-border-radius: 4px 0px 4px 0px;
  -moz-border-radius: 4px 0px 4px 0px;
  -ms-border-radius: 4px 0px 4px 0px;
  -o-border-radius: 4px 0px 4px 0px;
}

.add-ideal-consumption-grid-col > div > svg {
  color: white;
}

.submit-cta-ideal-consumption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  top: 0;
}

.submit-cta-ideal-consumption > button {
  background-color: rgba(51, 218, 189, 1);
  color: black;
  text-transform: uppercase;
  height: 48px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  /* transform: translateY(-50%); */
  right: 0;
  /* transform: rotate(90deg); */
}

.ideal-consumption-grid-col > img {
  width: 100%;
  height: 110px;
  transition: 0.3s ease-in-out;
  object-fit: cover;
  object-position: top;
  overflow-clip-margin: unset;
  overflow: hidden;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.input-grid-row {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 6px;
  margin-top: 10px;
}

.input-grid-row > input {
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.26);
  outline: none;
  font-size: 12px;
  padding: 0px 10px;
  height: 38px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.input-grid-row > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 218, 189, 1) !important;
  width: 28px !important;
  min-width: 28px !important;
  padding: 5px 10px !important;
  height: 38px !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.input {
  height: 46px;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  font-size: 12px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.submit-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.submit-cta > button {
  background-color: rgba(51, 218, 189, 1);
  height: 48px;
  width: 140px;
  font-weight: 800 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output {
  width: auto !important;
} */
