.category-size-ratio-grid-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  padding: 0px 20px;
}

.category-size-row > button:nth-child(1) {
  background-color: rgba(63, 74, 89, 1) !important;
  color: white !important;
}
