.dashboard-bg h1 {
  color: #1c1c1c;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 20px;
}
.card-common {
  padding: 7px;
}
.padding_left {
  padding-left: 7px;
}
.card-common h2 {
  color: #1c1c1c;
  font-size: 22px;
  font-weight: 700;
}
.card-common p {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 500;
}
.pale-red {
  border-radius: 4px;
  background: #ffb2b2;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.alice-blue {
  border-radius: 4px;
  background: #dbc4f0;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.light-green {
  border-radius: 4px;
  background: #d4e2d4;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.light-orange {
  border-radius: 4px;
  background: #ffc6ac;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.light-pink {
  border-radius: 4px;
  background: #fdcedf;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.dark-green {
  border-radius: 4px;
  background: #aec3ae;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.dark-gray {
  border-radius: 4px;
  background: #adc4ce;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.light-pitch {
  border-radius: 4px;
  background: #f2d8d8;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.dark-brown {
  border-radius: 4px;
  background: #d5b4b4;
  min-height: 130px;
  max-height: 130px;
  padding: 30px 24px;
}
.currnt-nodes {
  position: relative;
  margin: 0 auto;
}
.currnt-nodes img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.currnt-nodes .user_image_box {
  font-size: 12px;
}
.samplePic img {
  width: 60px;
  /* height: 102px; */
  border-radius: 4px;
}
.select-label {
  font-size: 12px;
  color: gray;
  padding-bottom: 8px;
}
.msg-noti {
  width: 18px;
  height: 18px;
  background-color: #33dabd;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #1c1c1c;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 5px; */
  position: absolute;
  top: 0px;
  left: 15px;
}
.table-bg {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #fff;
  padding: 10px;
}
.table-scroll::-webkit-scrollbar {
  width: 6px;
}
.table-scroll::-webkit-scrollbar-thumb {
  background: #9197b3;
  border-radius: 5px;
}
.view_submission_table .table-scroll {
  height: auto;
}

.view_submission_table h4 {
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 0px;
}
.ant-select-single .ant-select-selector:after,
.ant-select-single .ant-select-selector .ant-select-selection-item:empty:after,
.ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder:empty:after {
  content: url("../../public/Images/arrow-down.svg") !important;
}
.ant-select .ant-select-arrow {
  background-image: url("../../public/Images/arrow-down.svg");
  background-size: cover;
  color: transparent;
  width: 24px;
  height: 24px;
  margin-top: -11px;
}
.ant-select-selection-item {
  font-size: 12px;
  font-weight: 700 !important;
}
.ant-select-single .ant-select-selector {
  color: #1c1c1c;
  font-size: 12px;
}
.ant-picker .ant-picker-suffix {
  background-image: url("../../public/Images/calendar-2.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  color: transparent;
}
.ant-picker-focused {
  box-shadow: 0 0 0 2px rgb(5 145 255 / 0%);
}
.ant-picker-input input::placeholder {
  color: #1c1c1c !important;
  font-weight: 700;
}
.ant-select {
  width: 100%;
  height: 48px;
}
.pending-but button {
  border: 0px;
  border-radius: 10px;
  background: #f00;
  color: #fff;
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  line-height: 16px;
}
.pending-but a {
  color: #0036f5 !important;
  cursor: pointer;
  font-size: 10px;
  line-height: 16px;
  text-decoration-line: underline !important;
}
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 1040;
  cursor: pointer;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #3f4a59;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 130%;
  left: 50%;
  margin-left: -144px;
  height: auto;
}
.tooltip .tooltiptext a {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-bottom: 1px solid rgba(214, 214, 214, 0.16);
  line-height: 16px;
  display: block;
  padding: 10px;
  margin: 0px 10px;
  text-decoration: none;
}
.close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.remark-modal h4 {
  color: #1c1c1c;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  /* 127.273% */
  width: 225px;
  margin: 0 auto;
}
.modal-heading {
  display: flex;
  /* width: 100%;
    */
  justify-content: space-between;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 47px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent #3f4a59 transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.remark-select h6 {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 700;
  padding-top: 20px;
}
.remark-select .error-msg {
  font-size: 11px;
  color: red;
}
.remark-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid rgba(28, 28, 28, 0.26);
  height: 40px;
}
.remark-select .ant-select .ant-select-arrow {
  margin-top: -15px;
}
.ant-select-dropdown {
  z-index: 1111 !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker
  .ant-picker-input
  > input {
  font-size: 12px;
}
.modal-backdrop.show {
  opacity: 1;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(5px);
}
.remark-modal .modal-dialog {
  max-width: 356px;
}
.card_box {
  border: 1px solid rgba(28, 28, 28, 0.26);
  padding: 10px;
  width: 100%;
}
.card_box p {
  font-size: 12px;
  font-weight: 500;
  color: #1c1c1c;
}
textarea {
  color: #1c1c1c;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}
.upload-file {
  border-radius: 5px;
  border: 1px dashed #33dabd;
  background: #eafffb;
  text-align: center;
  margin-top: 15px;
  padding: 10px;
}
.upload-file h5 {
  color: #33dabd;
  font-size: 16px;
  font-weight: 700;
  padding: 7px;
}
.upload-file p {
  color: #1c1c1c;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 1px;
}
.upload-file small {
  color: rgba(28, 28, 28, 0.6);
  text-align: center;
  font-size: 8px;
  font-weight: 500;
}
.play-but {
  display: flex;
  justify-content: center;
  padding: 17px;
}
.play-but button {
  border: 0px;
  background-color: transparent;
}
.play-but img {
  margin: 5px;
}
.remark-buttons {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}
.gray-but {
  border-radius: 4px;
  background: #f3f5f6;
  border: 0px;
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
  max-width: 152px;
  min-width: 152px;
  height: 44px;
}
.gray-but a {
  color: #1c1c1c;
  text-decoration: none;
}
.loader_bg {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
}
.dark-but {
  border-radius: 4px;
  background: #33dabd;
  border: 0px;
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 152px;
  min-width: 152px;
  height: 44px;
}
.accordion-box {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #fff;
  margin-right: 10px;
}
.accordion-box h3 {
  border-bottom: 1px solid#D6D6D6;
  color: #1c1c1c;
  font-size: 22px;
  font-weight: 600;
  padding: 15px;
}

.workload-scroll {
  display: flex;
}
.workload-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}
.workload-section ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.workload-section ::-webkit-scrollbar-thumb {
  background-color: #9a9292;
  border-radius: 20px;
}
.heading h2 {
  color: #1c1c1c;
  font-size: 30px;
  font-weight: 600;
}
.allocate-hour {
  border-bottom: 1px solid#D6D6D6;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 15px 0px;
  margin: 0px 16px;
}
.allocate-hour h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.allocate-hour h6 {
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
}
/* .accordion_body {
    padding: 1rem 1.25rem;
} */

.task-icon .accordion-button {
  background-color: #e9e9e9;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
.accordion-button h2 {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.accordion-button:focus {
  border-color: #86b7fe00;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
.accordion-item {
  background-color: #fff;
  border: 0px;
}
.task-icon .accordion-button:not(.collapsed) {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  background-color: #e9e9e9;
  /* border-top: 1px solid #d6d6d6 !important; */
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
.task-icon .accordion-button::after {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-left: auto;
  content: "";
  background-image: url(../../public/Images/bottom-arrow.svg);
  transition: transform 0.2s ease-in-out;
  background-size: cover;
  padding: 24px;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../../public/Images/bottom-arrow.svg);
  transform: rotate(-180deg);
}
/* .bottom_a {
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    margin-left: auto;
    content: "";
    background-image: url(../../public/Images/bottom-arrow.svg);
    transform: rotate(-180deg);
    transition: transform .2s ease-in-out;
    background-size:cover;
    padding: 24px;
}
.top_a{
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    margin-left: auto;
    content: "";
    background-image: url(../../public/Images/bottom-arrow.svg);
    transition: transform .2s ease-in-out;
    background-size:cover;
    padding: 24px;
}
.border-icon-bottom{
    background-image: url(../../public/Images/arrow-down-gray.svg);
    transform: rotate(-180deg);
    transition: transform .2s ease-in-out;
    width: 20px;
    height: 20px;
    background-size:cover;
}
.border-icon-top{
    background-image: url(../../public/Images/arrow-down-gray.svg);
    width: 20px;
    height: 20px;
    transition: transform .2s ease-in-out;
    background-size:cover;
} */
.child-icons .accordion-button:not(.collapsed) {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  background-color: transparent !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}
.child-icons .accordion-button::after {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: auto;
  content: "";
  background-image: url(../../public/Images/arrow-down-gray.svg);
  transition: transform 0.2s ease-in-out;
  background-size: cover;
  padding: 10px;
}
.child-icons .accordion-button:not(.collapsed)::after {
  background-image: url(../../public/Images/arrow-down-gray.svg);
  transform: rotate(-180deg);
}
.accordion_inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 91%;
}
.ac-but button {
  padding: 20px 0px;
}
.border-im {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
}
.accordion_inside small {
  color: #1c1c1c;
  font-size: 8px;
  font-weight: 600;
}
.accordion_inside img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 5px;
  object-fit: cover;
  object-position: top;
  overflow-clip-margin: unset;
  overflow: hidden;
}
.accordion_body {
  position: relative;
}
.accord-body-right-border {
  position: absolute;
  right: -11px;
}
.accordion_inside h5 {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.accordion_inside h6 {
  color: #1c1c1c;
  text-align: right;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
}
.pattern-design {
  padding-left: 0px;
}
.pattern-design p {
  color: #1c1c1c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0px;
}
.pattern-design h5 {
  color: #1c1c1c;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0px;
  margin-left: 4px;
}
.pattern-design h6 {
  color: #1c1c1c;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
}
.green-border {
  border-right: 4px solid #33dabd;
  height: 56px;
  position: absolute;
  right: -11px;
}
.green-cir {
  border-radius: 50px;
  background: #33dabd;
  color: #1c1c1c;
  font-size: 8px;
  font-weight: 600;
  padding: 0;
  line-height: 28px;
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.red-border {
  border-right: 4px solid #f00;
  height: 56px;
  position: absolute;
  right: -11px;
}
.red-cir {
  border-radius: 50px;
  background: #f00;
  color: #1c1c1c;
  font-size: 8px;
  font-weight: 600;
  line-height: 28px;
  padding: 3px;
  margin-right: 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.gray-cir {
  border-radius: 50px;
  background: #d9d9d9;
  color: #1c1c1c;
  font-size: 8px;
  font-weight: 600;
  line-height: 28px;
  padding: 3px;
  margin-right: 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.yellow-border {
  border-right: 4px solid #ffd966;
  height: 56px;
  position: absolute;
  right: -11px;
}
.gray-border {
  border-right: 4px solid #d9d9d9;
  height: 56px;
  position: absolute;
  right: -11px;
}
.margin_left {
  margin-left: 4px;
}
.heading img {
  position: relative;
  bottom: 6px;
  cursor: pointer;
}
.view-but a {
  border-radius: 4px;
  text-decoration: none;
  padding: 12px 26px;
  background: #33dabd;
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
/* .traill-im-green{
    background-image: url("../../public/Images/green-cir.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 116px;
    height: 116px;

} */
.trail-img {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.trail-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.trail-green .trail-status {
  background-color: #00bb5ad4;
}
.trail-green {
  border: 2px solid #00bb5a;
}
.trail-red .trail-status {
  background-color: rgba(255, 0, 0, 0.868);
}
.trail-red {
  border: 2px solid #f00;
}
.trail-orange .trail-status {
  background-color: #f37a00d3;
}
.trail-orange {
  border: 2px solid #f37b00;
}
.trail-blackwhite {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.trail-status {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  padding: 8px 28px;
  text-align: center;
  color: #fff;
  font-size: 7px;
  font-weight: 600;
  line-height: 9px;
  width: 100%;
  margin: 0 auto;
}
.trail-status span {
  width: 67px;
  display: block;
  margin: 0 auto;
}
.blackwhite_status .trail-status-hold {
  background-color: rgba(0, 0, 0, 0.491);
}
.trail-status-hold {
  position: absolute;
  top: 0px;
  z-index: 1;
  padding: 8px 28px;
  text-align: center;
  line-height: 9px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trail-status-hold span {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  line-height: 23px;
}
/* toggle-switch */
.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 7px;
}
.inner {
  display: block;
  width: 284%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.innerHold {
  display: block;
  width: 284%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 35%;
  height: 14px;
  padding: 0;
  line-height: 14px;
  color: #1c1c1c;
  font-size: 6.5px;
  font-weight: 600;
  box-sizing: border-box;
}
.innerHold:before,
.innerHold:after {
  float: left;
  width: 35%;
  height: 14px;
  padding: 0;
  line-height: 14px;
  color: #1c1c1c;
  font-size: 6.5px;
  font-weight: 600;
  box-sizing: border-box;
}
.inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: #b2fff2;
  color: #1c1c1c;
}
.innerHold::before {
  content: "UnHold";
  padding-left: 7px;
  background-color: #b2fff2;
  color: #1c1c1c;
}
.inner:after {
  content: "Inactive";
  padding-right: 6px;
  background-color: #ffb2b2;
  color: #1c1c1c;
  text-align: right;
}
.innerHold:after {
  content: "Hold";
  padding-right: 9px;
  background-color: #ffb2b2;
  color: #1c1c1c;
  text-align: right;
}
.switch {
  display: block;
  width: 11px;
  height: 11px;
  margin: 1.9px 4px;
  background: #da3333;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 31px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.react-flow__node.react-flow__node-input.nopan.selectable,
.react-flow__node.react-flow__node-default.nopan.selectable {
  visibility: visible !important;
}
.react-flow__node.react-flow__node-input.nopan.selectable {
  border: 0;
}

.react-flow__node.react-flow__node-input.nopan.selectable
  .react-flow__handle.react-flow__handle-right.nodrag.nopan.source.connectable.connectablestart.connectableend.connectionindicator {
  display: none;
}

.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
  background: #33dabd;
}
.checkbox:checked + .label .innerHold {
  margin-left: 0;
}
.view_date_but a {
  text-decoration: none;
  background: #33dabd;
  border-radius: 12px;
  font-size: 10px;
  color: #000;
  font-weight: 600;
  padding: 4px 10px;
}
/* end */

.trail {
  /* border-radius: 4px;
border: 1px solid #D6D6D6;
background: #FFF; */
  padding: 20px;
  position: relative;
}
.sample-category-select {
  margin-right: 10px;
}
.bg-boxshadow {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #ffffff69; */
  z-index: 1;
  backdrop-filter: blur(5px);
}
.trail h3 {
  color: #1c1c1c;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.traill-text {
  width: 135px !important;
  text-align: center;
}
.traill-text h5 {
  color: #1c1c1c;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  clear: both;
  overflow: hidden;
  /* white-space: nowrap !important; */
  margin-bottom: 5px;
}
.trail-category-text h5 {
  color: #1c1c1c;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.sample-nodes-category-fit {
  max-width: 130px;
  margin: 10px auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clearDatas {
  padding-top: 20px;
}
.clear_data {
  font-size: 12px;
  color: #1c1c1c;
  font-weight: 600;
}
.clear_data:hover {
  color: #1c1c1c;
}
.traill-text .setting_a {
  border-radius: 12px;
  background: #33dabd;
  color: #1c1c1c;
  text-align: center;
  font-size: 9px;
  text-decoration: none;
  font-weight: 600;
  line-height: 16px;
  padding: 6px 11px 7px 11px;
}
.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 0px #1a192b;
}
.react-flow__node-default.selectable:hover,
.react-flow__node-input.selectable:hover,
.react-flow__node-output.selectable:hover,
.react-flow__node-group.selectable:hover {
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%);
}
.react-flow__edge.animated path {
  stroke-dasharray: 0;
}
.react-flow__handle {
  width: 0px;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  border: 0px;
  background-color: transparent;
}
/* connection-modal-css */
.connection-modal .modal-dialog {
  max-width: 1266px;
}
.flow-section {
  margin: 0 auto;
}
.connection-modal .flow-section {
  max-width: 1080px;
}
/* .hline:after { width:100%; height:1px; background: #fff; content:"" } */
.hline {
  width: 90%;
  height: 1px;
  background: #fff;
  /* border-bottom: 2px solid#3F4A59; */
  position: relative;
  top: 43%;
  left: 20px;
}
.dates-modal {
  background: rgb(0 0 0 / 26%);
  backdrop-filter: blur(5px);
}
.person-modal {
  background: rgb(0 0 0 / 26%);
  backdrop-filter: blur(5px);
}
.person-modal .modal-heading h4 {
  width: 240px;
  margin: 0 auto;
  color: #1c1c1c;
  font-size: 22px;
  font-weight: 600;
  padding-top: 5px;
}
.view_node_modal h4 {
  color: #1c1c1c;
  font-size: 24px;
  font-weight: 600;
  padding-top: 5px;
}
.heading-border {
  height: 1px;
  border-bottom: 1px solid #e5e5ef;
  padding: 10px 10px;
  margin: 0px 5px;
}

.dates-modal .modal-heading h4 {
  width: 100px;
  margin: 0 auto;
}
.person-modal .modal-dialog {
  max-width: 394px;
}
.view_node_modal .modal-dialog {
  max-width: 1024px;
}
.dates-box h5 {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 700;
}
.dates-card {
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.26);
  background: #fff;
  padding: 0px 14px;
}
.dates-box h6 {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  line-height: 16px;
}
.dates-box h6 b {
  font-weight: 800;
}
.workload-block {
  display: block !important;
  height: 450px;
  overflow: auto;
  overflow-x: hidden;
}
.workload-block::-webkit-scrollbar {
  width: 6px;
}
.workload-block::-webkit-scrollbar-thumb {
  background: #9197b3;
  border-radius: 5px;
}
.persion-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.persion-flex h6 {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  margin-bottom: 0px;
  padding-top: 7px;
}
.persion-flex p {
  color: #5c5c5c;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  margin-bottom: 0px;
}
.persion-flex img {
  border-radius: 42px;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.assigne {
  border-radius: 15px;
  background: #5c5c5c;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px; /* 160% */
  border: 0px;
  padding: 6px 15px;
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
  border-radius: 2px;
  /* background: #3F4A59; */
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
}
.ant-picker-dropdown .ant-picker-content th {
  /* color: #FFF; */
  font-size: 15px;
  font-weight: 500;
}

.ant-picker-dropdown .ant-picker-header button {
  padding: 0;
  /* color: #fff; */
}

.ant-picker-dropdown .ant-picker-footer-extra:not(:last-child) {
  border-bottom: 0px;
  padding: 0px 0px 20px 0px;
}
.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: 0px;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  background-color: #33dabdb0;
  border-radius: 6px;
  border: 0px;
}
.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #33dabd;
}
.ant-picker-dropdown .ant-picker-header > button:hover {
  color: #fff;
}
.ant-picker-cell {
  color: #ffffff63;
}
.cancel-b {
  border: 0px;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
}
.done-b {
  border: 0px;
  border-radius: 5px;
  background: #fff;
  color: #3f4a59;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  margin-left: 30px;
  height: 40px;
}
/* .ant-picker-dropdown .ant-picker-cell {
    padding: 6px 0;
    color: #ffffff67;
    cursor: pointer;
    
} */
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.input-custom {
  font-size: 12px;
  padding-left: 10px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.26);
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #ddd;
  outline: none;
}
.btn-recording {
  background-color: #f2f2f2;
  border-radius: 64px;
  border: 0px;
  width: 50px;
  height: 50px;
}
.btn-recording i {
  color: #1c1c1c;
}
.delete-but img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.mic-flex {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 84px;
}
.play-icon {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9999;
  margin-top: 10px;
  cursor: pointer;
  width: 90px;
}
.timer-set {
  margin-left: 2px;
  display: block;
  padding: 10px;
}
/* custom-calendar */
.react-daterange-picker__wrapper {
  border-radius: 4px;
  background: #fff;
  height: 48px;
  padding: 12px;
  width: 100%;
  border: 0px;
}
.ant-picker .ant-picker-suffix {
  background-image: url("../../public/Images/calendar-2.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  color: transparent;
}
.react-daterange-picker__calendar-button {
  background-image: url("../../public/Images/calendar-2.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  color: transparent;
}

.react-daterange-picker__calendar-button svg {
  display: none;
}
.react-calendar {
  border-radius: 2px;
  background: #3f4a59;
  text-align: center;

  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
}
.react-calendar__month-view__weekdays {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 15px 0px;
}
abbr[data-bs-original-title],
abbr[title] {
  text-decoration: none;
}
.react-calendar__navigation {
  padding: 18px 0px 0px;
}
.react-calendar__viewContainer {
  padding: 5px 23px;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  padding: 0px 65px;
}
.react-daterange-picker__inputGroup {
  font-size: 12px;
  font-weight: 500;
}
.react-daterange-picker {
  width: 100%;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile--active {
  background-color: #33dabd !important;
  color: #fff;
  border-radius: 1px;
}
.react-calendar button {
  border: 0px;
  background-color: transparent;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 24px;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 24px;
}
.react-flow__edge-path {
  stroke: #3f4a59;
  stroke-width: 2px;
  fill: none;
}
/* .react-flow__panel.left{
    display: none;
} */
.react-flow__panel.right {
  right: 0;
  display: none;
}
.trail-button-o {
  border-radius: 10px;
  border: 0.7px solid #ffc107;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  width: fit-content;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.trail-button-green {
  border-radius: 10px;
  background: #49a847;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  width: auto;
  padding: 0px 10px 0px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 3px;
}
.change_date {
  display: inline-block;
  margin-top: 9px;
}
.change_date a {
  border-radius: 10px;
  background: #33dabd;
  color: #1c1c1c;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  /* width: 34px; */
  height: 16px;
  text-decoration: none;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  border-width: 0px !important;
}
.persion-scroll {
  overflow: auto;
  overflow-x: hidden;
  height: 400px;
}
.persion-scroll::-webkit-scrollbar {
  width: 6px;
}
.persion-scroll::-webkit-scrollbar-thumb {
  background: #9197b3;
  border-radius: 5px;
}
@media (min-resolution: 140dpi) and (max-resolution: 150dpi) {
  .trail h3 {
    font-size: 18px;
  }
  .person-modal .modal-heading h4 {
    font-size: 18px;
  }
  .traill-text h5 {
    font-size: 10px;
  }
  .traill-im-green h6 {
    font-size: 5px;
  }
  .view-but a {
    font-size: 12px;
  }
  .heading h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .accordion_inside h5 {
    font-size: 12px;
  }
  .accordion_inside h6 {
    font-size: 12px;
  }
  .accordion-button {
    font-size: 14px;
  }
  .accordion-button:not(.collapsed) {
    font-size: 14px;
  }
  .allocate-hour h5 {
    font-size: 12px;
  }
  .allocate-hour h6 {
    font-size: 12px;
  }
  .accordion-box h3 {
    font-size: 18px;
  }
  .gray-but {
    font-size: 13px;
  }
  .dark-but {
    font-size: 13px;
  }
  .dashboard-bg h1 {
    font-size: 25px;
  }
  .card-common h2 {
    font-size: 18px;
    font-weight: 700;
  }
  .card-common p {
    color: #1c1c1c;
    font-size: 12px;
  }
  .remark-modal h4 {
    color: #1c1c1c;
    text-align: center;
    font-size: 20px;
  }
  .tooltip .tooltiptext a {
    font-size: 10px;
  }
  .ant-picker .ant-picker-input > input {
    font-size: 12px;
  }
  .remark-select h6 {
    font-size: 12px;
  }
  .upload-file h5 {
    font-size: 14px;
  }
  .upload-file p {
    font-size: 10px;
  }
  .upload-file small {
    font-size: 6px;
  }
}
.selectable {
  border: 0px solid #d7d7d7;
  background-color: transparent !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 1rem 3rem !important;
}
.userTextImageBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  background-color: #d7d7d7b3;
  font-size: 25px;
  font-weight: 600;
}
.user_image_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d7d7d7b3;
  font-size: 12px;
  /* border: 1px solid #9a9292; */
  font-weight: 600;
  text-transform: uppercase;
}
.inactiveNode {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #f00;
  /* background: linear-gradient(0deg, rgba(255, 0, 0, 0.50) 0%, rgba(255, 0, 0, 0.50) 100%); */
}
.inactiveNode::before {
  content: "Inactive";
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 600;
  left: 0;
  top: 0;
  background: #ff000087;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.setting_icn {
  width: 18px;
  height: 18px;
  margin: 5px 3px;
}

.edge_list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
.edge_list .gray-circle-edge {
  width: 28px;
  height: 28px;
  background-color: #a5a5a5;
  border-radius: 50%;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}

.opacity-5 {
  opacity: 0.5;
}
